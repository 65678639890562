import ROUTE_PATH from 'routes/config';
const { serviceView, event, serviceCenter, mypage } = ROUTE_PATH;

export const NAV_LIST_MANAGE = [
  {
    id: 1,
    title: '직원 관리로 이동',
    label: '직원 관리',
    desc: '직원을 초대하고 관리할 수 있어요',
    link: mypage.manager,
  },
];

export const NAV_LIST_DATA = [
  {
    id: 1,
    title: '데이터 연동 관리로 이동',
    label: '데이터 연동 관리',
    desc: '판매몰을 등록하고 정산예정금을 바로 확인해 보세요',
    link: mypage.dataLinkageManage,
  },
  {
    id: 2,
    title: '정산 예정금 관리로 이동',
    label: '정산 예정금 관리',
    desc: '대출 가능한 정산예정금을 확인할 수 있어요',
    link: mypage.estimatedAmountManage,
  },
];

export const NAV_LIST_LOAN = [
  {
    id: 1,
    title: '대출 비교하기로 이동',
    label: '대출 비교하기',
    desc: '여러 금융 기관의 대출을 비교하고 신청해 보세요',
    link: mypage.checkLoan,
  },
  {
    id: 2,
    title: '대출/데이터 제공 관리로 이동',
    label: '대출/데이터 제공 관리',
    desc: '대출과 데이터 제공 내역을 확인할 수 있어요',
    link: mypage.manageLoanDataProvide,
  },
];

//    label: '대출/데이터 이용 관리',
//    desc: '대출과 데이터 이용 내역을 확인할 수 있어요',

export const NAV_LIST_GUIDE = [
  {
    id: 1,
    title: '이벤트로 이동',
    label: '이벤트',
    link: event,
  },
  {
    id: 2,
    title: '고객센터로 이동',
    label: '고객센터',
    link: serviceCenter.main + '?tab=notice',
  },
  {
    id: 3,
    title: '서비스 소개로 이동',
    label: '서비스 소개',
    link: serviceView,
  },
];
