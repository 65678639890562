import React from 'react';
import { getKoreanFormatDate } from 'tools/DateTool';
import { TableDataCell, TableDataCell2, TableDataRow } from '../../style';

const DataProviderTableRow = ({ item, type }) => {
  if (type === 'threeCell') {
    return (
      <TableDataRow>
        <TableDataCell2>
          {item.mallAccountId ? (
            <span>
              {item.mallName}
              <br />
              아이디 : {item.mallAccountId}
            </span>
          ) : (
            <span>{item.mallName}</span>
          )}
        </TableDataCell2>
        <TableDataCell2 wordBreak="break-word">
          {item.settlementBeginDate &&
            item.settlementEndDate &&
            `${getKoreanFormatDate(item.settlementBeginDate)} ~
          ${getKoreanFormatDate(item.settlementEndDate)}`}
        </TableDataCell2>
        <TableDataCell2>
          {item.sumScheduledSettlementAmt?.toLocaleString()
            ? item.sumScheduledSettlementAmt?.toLocaleString()
            : 0}
          원
        </TableDataCell2>
      </TableDataRow>
    );
  }
  if (type === 'twoCell') {
    return (
      <TableDataRow>
        <TableDataCell2>{item.mallName}</TableDataCell2>
        <TableDataCell2>
          {item.settlementBeginDate &&
            item.settlementEndDate &&
            `${getKoreanFormatDate(item.settlementBeginDate)} ~
          ${getKoreanFormatDate(item.settlementEndDate)}`}
          {!item.settlementBeginDate &&
            !item.settlementEndDate &&
            `정산 기간에 제한이 없습니다.`}
        </TableDataCell2>
      </TableDataRow>
    );
  }
  if (type === 'custom') {
    if (typeof item === 'object' && item !== null) {
      return (
        <TableDataRow>
          {Object.keys(item).map((key, index) => {
            return (
              <TableDataCell2 key={`TableDataCell_${key}_${index}`}>
                {item[key]}
              </TableDataCell2>
            );
          })}
        </TableDataRow>
      );
    }
    return;
  }

  return (
    <TableDataRow>
      <TableDataCell>{item.dataProviderName}</TableDataCell>
      <TableDataCell>{item.allCount ?? '?'} 건</TableDataCell>
      <TableDataCell>{item.availableCount ?? '?'} 건</TableDataCell>
      <TableDataCell>{item.creditAvailableCount ?? '?'} 건</TableDataCell>
    </TableDataRow>
  );
};

export default DataProviderTableRow;
