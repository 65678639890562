import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ROUTE_PATH from 'routes/config';
import { TwoBtnModal } from 'components/Modal';
import { MYPAGE_PATH, PAGE_LEAVE_PATH } from '../constants';
import { modalMessages } from './constants';
import FeatherIcon from 'feather-icons-react';
import theme from 'styles/theme';
import { ScrollArea, Contents } from 'components/Modal/style';
import {
  BackPageTopbarHeader,
  HeaderH2,
  RightLayoutContainer,
  LeftLayoutContainer,
} from './style';

const BackPageTopbar = ({
  pageTitle,
  pathname,
  authenticated,
  showBanner = false,
}) => {
  const navigate = useNavigate();
  const { mypage } = ROUTE_PATH;
  const [showTwoBtnModal, setShowTwoBtnModal] = useState(false);

  // 회원가입, 사업자정보변경, 대출신청, 데이터연동 페이지에서 BackButton 클릭시 페이지이탈 경고모달창
  const onClickBackButton = () => {
    //대출 한도 조회 상세에서는 navigate (loanLimit)
    if (
      pathname === mypage.checkLoanDetail ||
      pathname === mypage.checkCreditLoanDetail
    ) {
      navigate(-1, { state: 'inquire-list' });
    } else {
      PAGE_LEAVE_PATH.includes(pathname)
        ? setShowTwoBtnModal(true)
        : navigate(-1, { state: null });
    }
  };

  const modalMessage = pathname.includes(mypage.checkLoan + '/')
    ? modalMessages[mypage.checkLoan]
    : modalMessages[pathname];

  const onClickRight = () => {
    setShowTwoBtnModal(false);
    navigate(-1, { state: null });
  };

  return (
    <div>
      <BackPageTopbarHeader showBanner={showBanner}>
        <LeftLayoutContainer>
          <FeatherIcon
            icon="chevron-left"
            size={24}
            color={theme.color.grey3}
            onClick={onClickBackButton}
          />
          <FeatherIcon
            icon="home"
            size={22}
            color={theme.color.grey3}
            onClick={() => {
              navigate('/');
            }}
          />
        </LeftLayoutContainer>
        <HeaderH2>{pageTitle}</HeaderH2>
        {/* h1의 hide내 텍스트는 화면에는 비노출되나, 검색엔진 및 리더기용으로 존재합니다. */}
        {MYPAGE_PATH.includes(pathname) ? (
          <RightLayoutContainer />
        ) : authenticated ? (
          <RightLayoutContainer>
            <FeatherIcon
              icon="menu"
              size={22}
              color={theme.color.grey3}
              onClick={() => {
                navigate(mypage.main);
              }}
            />
          </RightLayoutContainer>
        ) : (
          <RightLayoutContainer>
            <FeatherIcon
              icon="user"
              size={22}
              color={theme.color.grey3}
              onClick={() => {
                navigate(mypage.noAuth);
              }}
            />
          </RightLayoutContainer>
        )}
      </BackPageTopbarHeader>
      <TwoBtnModal
        showModal={showTwoBtnModal}
        leftButton="아니오"
        rightButton="예"
        onClose={() => {
          setShowTwoBtnModal(false);
        }}
        onClick={onClickRight}
      >
        <ScrollArea>
          <Contents>{modalMessage}</Contents>
        </ScrollArea>
      </TwoBtnModal>
    </div>
  );
};

export default BackPageTopbar;
