export const AGREE_LIST = [
  {
    stipulationSeq: 0, // 고유한 값
    dataProvideAgreeStipulationSeq: 0,
    stipulationTypeCd: 'STC100',
    stipulationCdName: '',
    stipulationContents: '',
    required: false,
    checked: false,
  },
];

export const KEY_GUIDE = {
  SECURED: `[주요 안내]
  데이터 제공 동의 시, 동의한 판매몰의 정산 기간 내 정산 예정금은 타 금융기관에 담보로 제공할 수 없습니다.\n대출 비교 시 해당 정산 예정금은 사용 불가 합니다.`,
  UNSECURED: `[주요 안내]
  동의한 판매몰의 정산 기간 내 정산 예정금은 해당 금융기관 제공 후에도 타 금융기관에 데이터 제공 혹은 담보 대출 신청 시 사용 가능합니다.`,
};
