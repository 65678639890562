import React, { useState, createContext } from 'react';
import Route from 'routes';
import useAuth from 'hooks/useAuth';
import ScrollToTop from 'helpers/ScrollToTop';
import GlobalStyle from 'styles/globalStyle';

export const AppContext = createContext(undefined);

const App = () => {
  const { isLoading, ...auth } = useAuth({});
  const [savedCheckLoanPageData, setSavedCheckLoanPageData] = useState({});
  return (
    <div className="App">
      <AppContext.Provider
        value={{
          auth,
          savedCheckLoanPageData,
          setSavedCheckLoanPageData,
        }}
      >
        <ScrollToTop />
        <GlobalStyle />
        <Route />
      </AppContext.Provider>
    </div>
  );
};

export default App;
