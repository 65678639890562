import React, { useEffect, useState } from 'react';
import { createMarkup } from 'tools/WebTool';
import ROUTE_PATH from 'routes/config';
import StipulationAgreeRow from 'components/StipulationAgreeRow';
import {
  PolicyAgreeAllCheckbox,
  PolicyAgreeAllCheckboxIcon,
  PolicyAgreeAllCheckboxLabel,
  PolicyAgreeAllWrapper,
} from 'pages/Login/JoinMemberPage/JoinMemberStep2/style';
import { Contents } from 'components/Modal/style';
import { StipulationWrapper } from './style';

const EventStipulation = ({
  stipulationList,
  setPostData,
  checkUserLogin = event => {},
}) => {
  const [checkableStipulationList, setCheckableStipulationList] = useState([]);
  const [textStipulationList, setTextStipulationList] = useState([]);

  useEffect(() => {
    if (stipulationList && stipulationList.length > 0) {
      setCheckableStipulationList(
        stipulationList
          .filter(item => item.stipulationTypeCd === 'STC100') // 약관타입
          .map(item => ({
            ...item,
            required: item.essentialYn === 'Y',
            stipulationSeq: item.eventStipulationMappingSeq,
            checked: false,
          }))
          .sort((a, b) => b.required - a.required) // '필수'를 앞에 정렬
      );

      setTextStipulationList(
        stipulationList.filter(item => item.stipulationTypeCd === 'STC200') // 텍스트타입
      );
    }
  }, [stipulationList]);

  const isAllChecked =
    textStipulationList.length > 0
      ? textStipulationList.length ===
        textStipulationList.filter(item => item.checked === true).length
      : checkableStipulationList.length > 0
      ? checkableStipulationList.length ===
        checkableStipulationList.filter(item => item.checked === true).length
      : false;

  useEffect(() => {
    const getStipulationSeq = list => {
      setPostData(prevState => ({
        ...prevState,
        agreedEventStipulationMappingSeqList: list
          .filter(item => item.checked === true)
          .map(({ eventStipulationMappingSeq }) => eventStipulationMappingSeq),
        disagreedEventStipulationMappingSeqList: list
          .filter(item => !item.checked)
          .map(({ eventStipulationMappingSeq }) => eventStipulationMappingSeq),
      }));
    };

    if (textStipulationList.length > 0) {
      getStipulationSeq(textStipulationList);
      return;
    }

    getStipulationSeq(checkableStipulationList);
  }, [checkableStipulationList, textStipulationList]);

  const onChangeAll = event => {
    checkUserLogin(event);
    const { checked } = event.target;

    if (textStipulationList.length > 0) {
      setTextStipulationList(prev =>
        prev.map(item => {
          return { ...item, checked: checked };
        })
      );
      return;
    }

    setCheckableStipulationList(prev =>
      prev.map(item => {
        return { ...item, checked: checked };
      })
    );
  };

  const onChangeCheckBox = event => {
    checkUserLogin(event);
    const { value, checked } = event.target;
    setCheckableStipulationList(prev =>
      prev.map(item => {
        return item.stipulationSeq === Number(value)
          ? { ...item, checked: checked }
          : item;
      })
    );
  };

  const onClickViewBtn = stipulationSeq => {
    if (!stipulationSeq) {
      return;
    }
    window._stipulationData = checkableStipulationList?.find(
      item => item.stipulationSeq === stipulationSeq
    );
    window.open(`${ROUTE_PATH.policyDefault}/${stipulationSeq}`, '_blank');
  };

  return (
    <div>
      <PolicyAgreeAllWrapper>
        <PolicyAgreeAllCheckboxLabel>
          <PolicyAgreeAllCheckbox
            type="checkbox"
            onChange={onChangeAll}
            checked={isAllChecked}
            readOnly
          />
          모두 동의합니다.
          <PolicyAgreeAllCheckboxIcon />
        </PolicyAgreeAllCheckboxLabel>
      </PolicyAgreeAllWrapper>

      <StipulationWrapper>
        {checkableStipulationList.length > 0
          ? checkableStipulationList.map((item, index) => (
              <StipulationAgreeRow
                key={`checkable_stipulation_${item.eventStipulationMappingSeq}_${index}`}
                item={item}
                index={index}
                onChangeCheckBox={onChangeCheckBox}
                onClickViewBtn={onClickViewBtn}
                lastRequiredIndex={checkableStipulationList
                  .map(({ required }) => required)
                  .lastIndexOf(true)}
              />
            ))
          : textStipulationList.map((item, index) => (
              <Contents
                key={`text_stipulationList_${item.stipulationSeq}_${index}`}
                dangerouslySetInnerHTML={createMarkup(
                  item.stipulationContents || ''
                )}
              />
            ))}
      </StipulationWrapper>
    </div>
  );
};

export default EventStipulation;
