import React, { useEffect, useState } from 'react';
import { createMarkup } from 'tools/WebTool';
import ROUTE_PATH from 'routes/config';
import StipulationAgreeRow from 'components/StipulationAgreeRow';
import {
  PolicyAgreeAllCheckbox,
  PolicyAgreeAllCheckboxIcon,
  PolicyAgreeAllCheckboxLabel,
  PolicyAgreeAllWrapper,
} from 'pages/Login/JoinMemberPage/JoinMemberStep2/style';
import { StipulationWrapper } from './style';
import { StipulationContents } from 'components/StipulationAgreeRow/style';

const DataInterlockStipulation = ({ stipulationList, setPostData }) => {
  const [checkableStipulationList, setCheckableStipulationList] = useState([]);
  const [textStipulationList, setTextStipulationList] = useState([]);

  //진입 시점
  useEffect(() => {
    if (stipulationList && stipulationList.length > 0) {
      setCheckableStipulationList(
        stipulationList
          .filter(item => item.stipulationTypeCd === 'STC100') // 약관타입
          .map(item => ({
            ...item,
            required: item.essentialYn === 'Y',
            dataProviderMallStipulationMappingSeq:
              item.dataProviderMallStipulationMappingSeq,
            checked: false,
          }))
          .sort((a, b) => b.required - a.required) // '필수'를 앞에 정렬
      );

      setTextStipulationList(
        stipulationList
          .filter(item => item.stipulationTypeCd === 'STC200')
          .map(item => ({
            ...item,
            required: item.essentialYn === 'Y',
            dataProviderMallStipulationMappingSeq:
              item.dataProviderMallStipulationMappingSeq,
            checked: false,
          })) // 텍스트타입
      );
    }
  }, [stipulationList]);

  const isAllChecked =
    textStipulationList.length > 0
      ? textStipulationList.length ===
        textStipulationList.filter(item => item.checked === true).length
      : checkableStipulationList.length > 0
      ? checkableStipulationList.length ===
        checkableStipulationList.filter(item => item.checked === true).length
      : false;

  const getStipulationSeq = list => {
    setPostData(prevState => ({
      ...prevState,
      agreeStipulationList: list?.map(item => {
        return {
          dataProviderMallStipulationMappingSeq:
            item?.dataProviderMallStipulationMappingSeq,
          stipulationSubject: item?.stipulationCdName,
          stipulationContents: item?.stipulationContents,
          agreeYn: item?.checked,
          required: item?.required,
        };
      }),
    }));
  };

  //postData 심어주기
  useEffect(() => {
    //텍스트 약관일 경우
    if (textStipulationList?.length > 0) {
      getStipulationSeq(textStipulationList);
    }
  }, [textStipulationList]);

  //postData 심어주기
  useEffect(() => {
    // 약관 타입 약관일 경우
    if (checkableStipulationList?.length > 0) {
      getStipulationSeq(checkableStipulationList);
    }
  }, [checkableStipulationList]);

  //전체 동의
  const onChangeAll = event => {
    const { checked } = event.target;

    if (textStipulationList.length > 0) {
      setTextStipulationList(prev =>
        prev.map(item => {
          return { ...item, checked: checked };
        })
      );
      return;
    }

    setCheckableStipulationList(prev =>
      prev.map(item => {
        return { ...item, checked: checked };
      })
    );
  };

  //약관 선택
  const onChangeCheckBox = event => {
    const { value, checked } = event.target;
    setCheckableStipulationList(prev =>
      prev.map(item => {
        return item.dataProviderMallStipulationMappingSeq === Number(value)
          ? { ...item, checked: checked }
          : item;
      })
    );
  };

  //약관 전체보기
  const onClickViewBtn = dataProviderMallStipulationMappingSeq => {
    if (!dataProviderMallStipulationMappingSeq) {
      return;
    }
    window._stipulationData = checkableStipulationList?.find(
      item =>
        item.dataProviderMallStipulationMappingSeq ===
        dataProviderMallStipulationMappingSeq
    );
    window.open(
      `${ROUTE_PATH.policyDefault}/${dataProviderMallStipulationMappingSeq}`,
      '_blank'
    );
  };

  return (
    <div>
      <PolicyAgreeAllWrapper>
        <PolicyAgreeAllCheckboxLabel>
          <PolicyAgreeAllCheckbox
            type="checkbox"
            onChange={onChangeAll}
            checked={isAllChecked}
            readOnly
          />
          모두 동의합니다.
          <PolicyAgreeAllCheckboxIcon />
        </PolicyAgreeAllCheckboxLabel>
      </PolicyAgreeAllWrapper>

      {checkableStipulationList.length > 0 && (
        <StipulationWrapper>
          {checkableStipulationList.map((item, index) => (
            <StipulationAgreeRow
              key={`checkable_stipulation_${item.dataProviderMallStipulationMappingSeq}_${index}`}
              item={item}
              index={index}
              onChangeCheckBox={onChangeCheckBox}
              onClickViewBtn={onClickViewBtn}
              stipulationSeq="dataProviderMallStipulationMappingSeq"
              lastRequiredIndex={checkableStipulationList
                .map(({ required }) => required)
                .lastIndexOf(true)}
            />
          ))}
        </StipulationWrapper>
      )}

      {textStipulationList.length > 0 &&
        textStipulationList.map((item, index) => (
          <StipulationContents
            key={`text_stipulationList_${item.dataProviderMallStipulationMappingSeq}_${index}`}
            dangerouslySetInnerHTML={createMarkup(
              item.stipulationContents || ''
            )}
          />
        ))}
    </div>
  );
};

export default DataInterlockStipulation;
