import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ROUTE_PATH from 'routes/config';
import { createMarkup } from 'tools/WebTool';
import { modifyDataRequest } from 'utils/request';
import { TwoBtnModal } from 'components/Modal';
import { Contents, ScrollArea } from 'components/Modal/style';

const DeepLinkAgreeTwoBtnModal = ({
  dataProvideAgreeSeq,
  isOpenRejectModal = false,
  setIsOpenRejectModal = () => {},
  showTwoBtnModal = false,
  setShowTwoBtnModal = () => {},
  setBtnModalMessage = () => {},
  btnModalMessage = '',
  setAlertMessage = () => {},
}) => {
  const navigate = useNavigate();
  // api 통신 로딩 (로딩 중: true)
  const [isApiLoading, setIsApiLoading] = useState(false);

  // 데이터 제공 동의 거절
  const onCloseRejectModal = () => {
    setBtnModalMessage('');
    setShowTwoBtnModal(false);
    setIsOpenRejectModal(false);
  };

  // '데이터 제공 동의 거절하기' 클릭후 모달창에서 '예, 거절할게요' 버튼 누를때
  const onClickRejectBtn = () => {
    modifyDataRequest({
      url: `/v1/au/data-provide-agree/disagree/${dataProvideAgreeSeq}`,
      setModalMessage: setAlertMessage,
      setIsLoading: setIsApiLoading,
    });
    setShowTwoBtnModal(false);
    setIsOpenRejectModal(false);
  };

  //  판매몰 등록 여부 0건(error) or 동의 완료(success) 모달 이동 버튼
  const onClickGoToDataLinkageManage = () => {
    setShowTwoBtnModal(false);
    navigate(ROUTE_PATH.mypage.dataLinkageManage);
  };

  //  판매몰 등록 여부 0건(error) or 동의 완료(success) 모달 닫기 버튼
  const onCloseTwoBtnModal = () => {
    setBtnModalMessage('');
    setShowTwoBtnModal(false);
    navigate('/');
  };
  return isOpenRejectModal ? (
    <TwoBtnModal
      showModal={showTwoBtnModal}
      onClose={onCloseRejectModal}
      onClick={onClickRejectBtn}
      rightButtonDisabled={isApiLoading}
      leftButton="아니요."
      rightButton="예, 거절할게요"
    >
      <ScrollArea>
        <Contents>
          {`데이터 제공 동의를 거절하시겠어요?
            데이터 제공 동의 거절 시 금융기관에
            데이터를 제공할 수 없습니다.`}
        </Contents>
      </ScrollArea>
    </TwoBtnModal>
  ) : (
    <TwoBtnModal
      showModal={showTwoBtnModal}
      onClose={onCloseTwoBtnModal}
      onClick={onClickGoToDataLinkageManage}
      leftButton="닫기"
      rightButton="데이터 연동하기"
    >
      <ScrollArea>
        <Contents
          textAlign="left"
          dangerouslySetInnerHTML={createMarkup(btnModalMessage)}
        />
      </ScrollArea>
    </TwoBtnModal>
  );
};

export default DeepLinkAgreeTwoBtnModal;
