import React, { useEffect, useState } from 'react';
import ROUTE_PATH from 'routes/config';
import { getListDataRequest } from 'utils/request';
import SelectManager from 'components/SelectManager';
import { OneBtnModal } from 'components/Modal';
import { ScrollArea, Contents, NoScrollArea } from 'components/Modal/style';
import {
  ContentData,
  TextBox,
} from '../../../LoanRequest/SelectManagerModal/SelectManager/style';
import { BlueLink } from '../style';

const SelectManagerModal = ({
  showSelectManagerModal = false,
  setShowSelectManagerModal,
  mallName = '',
  setDataInterlockInfo,
  setInitData = {},
  showFilterModal = false,
  additionalFnc = () => {},
  managerName = '',
}) => {
  const [validManagerList, setValidManagerList] = useState([]);
  const [isLoadingOver, setIsLoadingOver] = useState(false);
  const [checkStatusList, setCheckStatusList] = useState([]);
  const [selectedManager, setSelectedManager] = useState({});

  useEffect(() => {
    if (!!showSelectManagerModal) {
      const successFnc = listData => {
        const validData = listData?.filter(
          item =>
            item.useYn === 'Y' &&
            (item.representationYn === 'Y' ||
              item.invitationCompletionYn === 'Y')
        );
        setValidManagerList(validData);
      };
      getListDataRequest({
        url: '/v1/au/manager',
        successFnc,
        setIsLoadingOver,
      });
    }
  }, [showSelectManagerModal]);

  useEffect(() => {
    setCheckStatusList(
      validManagerList.map(item => {
        return {
          businessManagerSeq: item.businessManagerSeq,
          managerName: item.managerName,
          mobilePhone: item.mobilePhone,
          department: item.department,
          position: item.position,
          isChecked: item.managerName === managerName || false, // 선택했던 이름 모달창 다시 열었을때 디폴트로 선택되기
        };
      })
    );
  }, [validManagerList, managerName]);

  const onClickSelectManager = () => {
    setShowSelectManagerModal(false);
    !!setDataInterlockInfo &&
      typeof setDataInterlockInfo === 'function' &&
      setDataInterlockInfo(prev => ({
        ...prev,
        name: selectedManager.managerName,
        mobilePhone: selectedManager.mobilePhone,
      }));
    !!setInitData &&
      typeof setInitData === 'function' &&
      setInitData(prev => ({
        ...prev,
        name: selectedManager.managerName,
      }));
    additionalFnc(selectedManager);
  };
  const onClickBlueLink = () => {
    setShowSelectManagerModal(false);
    setValidManagerList([]);
    window.open(ROUTE_PATH.mypage.manager, '_blank');
  };

  return (
    isLoadingOver === true && (
      <OneBtnModal
        showModal={showSelectManagerModal}
        onClose={() => setShowSelectManagerModal(false)}
        onClick={onClickSelectManager}
        btnContent="담당자 선택"
        border={showFilterModal}
        disabled={!selectedManager?.managerName}
      >
        <ScrollArea style={{ marginBottom: '25px' }}>
          <Contents>
            <SelectManager
              mallName={mallName}
              managerList={validManagerList}
              setSelectedManager={setSelectedManager}
              setIsLoadingOver={setIsLoadingOver}
              checkStatusList={checkStatusList}
              setCheckStatusList={setCheckStatusList}
            />
          </Contents>
        </ScrollArea>

        <NoScrollArea>
          <TextBox style={{ margin: '30px 0' }}>
            <ContentData style={{ fontSize: '1.3rem' }}>
              선택한 담당자의 휴대폰 번호가 정확한지 확인해 주세요.
              <br />
              연동 실패 혹은 2차 인증 필요시 안내 문자가 발송될 예정입니다.
              <br />
              휴대폰 번호 수정 또는 신규 담당자 추가가 필요하다면? <br />
              <BlueLink
                onClick={onClickBlueLink}
                target="_blank"
                tabIndex={0}
                title="직원관리 페이지로 이동"
              >
                휴대폰 번호 수정하기
              </BlueLink>
            </ContentData>
          </TextBox>
        </NoScrollArea>
      </OneBtnModal>
    )
  );
};

export default SelectManagerModal;
