import styled from 'styled-components';
import theme from 'styles/theme';

export const ModalSectionTitle = styled.div`
  margin: 20px 0px;
  margin-bottom: 10px;
`;

export const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30%;
  height: 100px;
  background: ${theme.borderColor.contentBox};
  margin: 5px;
  border-radius: 5px;
  text-align: center;
`;

export const ContentImg = styled.img`
  margin: 15px 10px 0px 10px;
  width: 51px;
  height: 51px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContentImgNull = styled.div`
  margin: 20px 10px 0px 10px;
  width: 51px;
  height: 51px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${theme.backgroundColor.buttonLightGrey};
  font-size: 1rem;
  white-space: nowrap;
  color: ${theme.color.white};
`;

export const ContentText = styled.div`
  width: 100%;
  height: 40%;
  font-size: 1.2rem;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
`;

export const SecondAuthAlertText = styled.div`
  color: ${theme.color.red};
  font-size: 14px;
  line-height: 1.8rem;
`;

export const SecondAuthCheckBoxArea = styled.div`
  display: flex;
`;

export const SecondAuthArea = styled.section`
  margin-top: 30px;
  padding: 30px 35px 0 35px;
  border-top: 5px solid ${theme.color.grey12};
`;

export const DataFnqWrap = styled.div`
  padding: 20px 35px 0px 35px;
  background-color: ${theme.backgroundColor.footerBox};
`;
